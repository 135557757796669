import { configureStore } from '@reduxjs/toolkit'
import UserReducer from './reducers/UserReducer'
import SideBarReducer from './reducers/SideBarReducer'
import LocalizationReducer from './reducers/LocalizationReducer'
import ClientReducer from './reducers/ClientReducer'
import EquipmentReducer from './reducers/EquipmentReducer'
import BrandReducer from './reducers/BrandReducer'
import ModelReducer from './reducers/ModelReducer'
import PartReducer from './reducers/PartReducer'
import OrderReducer from './reducers/OrderReducer'
import OrderPartReducer from './reducers/OrderPartReducer'
import MaintenanceTypesReducer from './reducers/MaintenanceTypesReducer'
import EquipmentDeliveryAgreementReducer from './reducers/EquipmentDeliveryAgreementReducer'

export default configureStore({
  reducer: {
    users: UserReducer,
    sidebar: SideBarReducer,
    localization: LocalizationReducer,
    clients: ClientReducer,
    equipments: EquipmentReducer,
    brands: BrandReducer,
    models: ModelReducer,
    parts: PartReducer,
    orders: OrderReducer,
    orderparts: OrderPartReducer,
    maintenancetypes: MaintenanceTypesReducer,
    deliveryagreement: EquipmentDeliveryAgreementReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
