import { createSlice } from '@reduxjs/toolkit'

const initialEquipmentDeliveryAgreement = {
  equipmentId: '',
  brandId: '',
  modelId: '',
  clientId: '',
  recomendations: '',
  observations: '',
  serieNo: '',
  company: '',
  plate: '',
  driver: '',
  driverIdentification: '',
  address: '',
  fuelLevel: 0,
  damage: '',
  purchaseOrder: '',
  contactPerson: '',
  contactPhone: '',
  createdDate: new Date(),
}

const initialEquipmentDeliveryAgreements = {
  items: [],
}

const initialFilter = {}

export const EquipmentDeliveryAgreementSlice = createSlice({
  name: 'EquipmentDeliveryAgreement',
  initialState: {
    EquipmentDeliveryAgreements: { ...initialEquipmentDeliveryAgreements },
    EquipmentDeliveryAgreement: { ...initialEquipmentDeliveryAgreement },
    filter: { ...initialFilter },
    deleteEquipmentDeliveryAgreementId: null,
  },
  reducers: {
    GetEquipmentDeliveryAgreements: (state, action) => {
      state.EquipmentDeliveryAgreements = action.payload
    },
    AddEquipmentDeliveryAgreement: (state, action) => {
      state.EquipmentDeliveryAgreement = { ...initialEquipmentDeliveryAgreement }
    },
    UpdateEquipmentDeliveryAgreement: (state, action) => {
      state.EquipmentDeliveryAgreement = { ...initialEquipmentDeliveryAgreement, ...action.payload }
    },
    DeleteEquipmentDeliveryAgreement: (state, action) => {
      state.deleteEquipmentDeliveryAgreementId = action.payload
    },
    SetEquipmentDeliveryAgreement: (state, action) => {
      state.EquipmentDeliveryAgreement = action.payload
    },
    SetFilter: (state, action) => {
      state.filter = action.payload
    },
    ClearFilter: (state, action) => {
      state.filter = { ...initialFilter }
    },
  },
})

export const {
  GetEquipmentDeliveryAgreements,
  AddEquipmentDeliveryAgreement,
  UpdateEquipmentDeliveryAgreement,
  DeleteEquipmentDeliveryAgreement,
  SetEquipmentDeliveryAgreement,
  SetFilter,
  ClearFilter,
} = EquipmentDeliveryAgreementSlice.actions

export default EquipmentDeliveryAgreementSlice.reducer
