import { createSlice } from '@reduxjs/toolkit'

const initialOrder = {
  equipmentId: '',
  brandId: '',
  modelId: '',
  clientId: '',
  workDone: '',
  recomendations: '',
  observations: '',
  serieNo: '',
  hd: 0,
  km: 0,
  hl: 0,
  tm: 0,
  typeId: 1,
  reportedBy: '',
  situation: '',
  createdDate: new Date(),
  invoiceTo: '',
}

const initialOrders = {
  items: [],
}

const initialFilter = {
  typeId: '',
  statusId: '',
}

export const OrderSlice = createSlice({
  name: 'Order',
  initialState: {
    orders: { ...initialOrders },
    order: { ...initialOrder },
    filter: { ...initialFilter },
    deleteOrderId: null,
  },
  reducers: {
    GetOrders: (state, action) => {
      state.orders = action.payload
    },
    AddOrder: (state, action) => {
      state.order = { ...initialOrder }
    },
    UpdateOrder: (state, action) => {
      state.order = { ...initialOrder, ...action.payload }
    },
    DeleteOrder: (state, action) => {
      state.deleteOrderId = action.payload
    },
    SetOrder: (state, action) => {
      state.order = action.payload
    },
    SetFilter: (state, action) => {
      state.filter = action.payload
    },
    ClearFilter: (state, action) => {
      state.filter = { ...initialFilter }
    },
  },
})

export const { GetOrders, AddOrder, UpdateOrder, DeleteOrder, SetOrder, SetFilter, ClearFilter } =
  OrderSlice.actions

export default OrderSlice.reducer
