import { createSlice } from '@reduxjs/toolkit'

const initialClient = {
  code: '',
  name: '',
  phone: '',
  email: '',
}

const initialClients = {
  items: [],
}

export const ClientSlice = createSlice({
  name: 'Client',
  initialState: {
    clients: { ...initialClients },
    addingClient: { ...initialClient },
    updatingClient: { ...initialClient },
    deleteClientId: null,
    visibleAdd: false,
    visibleUpdate: false,
  },
  reducers: {
    GetClients: (state, action) => {
      state.clients = action.payload
    },
    GetClientsNoPaginated: (state, action) => {
      state.clients.items = action.payload
    },
    OpenAddClient: (state, action) => {
      state.visibleAdd = true
      state.addingClient = { ...initialClient }
    },
    CloseAddClient: (state, action) => {
      state.visibleAdd = false
    },
    OpenUpdateClient: (state, action) => {
      state.visibleUpdate = true
      state.updatingClient = action.payload
    },
    CloseUpdateClient: (state, action) => {
      state.visibleUpdate = false
      state.updatingClient = { ...initialClient }
    },
    AddClient: (state, action) => {
      state.addingClient = { ...initialClient }
    },
    UpdateClient: (state, action) => {
      state.updatingClient = action.payload
    },
    DeleteClient: (state, action) => {
      state.deleteClientId = action.payload
    },
  },
})

export const {
  GetClients,
  GetClientsNoPaginated,
  OpenAddClient,
  AddClient,
  CloseAddClient,
  UpdateClient,
  DeleteClient,
  OpenUpdateClient,
  CloseUpdateClient,
} = ClientSlice.actions

export default ClientSlice.reducer
