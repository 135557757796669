import { createSlice } from '@reduxjs/toolkit'

export const MaintenanceTypesSlice = createSlice({
  name: 'MaintenanceTypes',
  initialState: {
    items: [],
    maintenances: [],
  },
  reducers: {
    setMaintenanceTypes: (state, action) => {
      state.items = action.payload
    },
    AddMaintenance: (state, action) => {
      state.maintenances = [...state.maintenances, action.payload]
    },
    RemoveMaintenance: (state, action) => {
      let filteredItems = state.maintenances.indexOf(action.payload)
      if (filteredItems >= 0) {
        state.maintenances.splice(filteredItems, 1)
      }
    },
    SetMaintenance: (state, action) => {
      state.maintenances = action.payload
    },
  },
})

export const { setMaintenanceTypes, AddMaintenance, RemoveMaintenance, SetMaintenance } =
  MaintenanceTypesSlice.actions

export default MaintenanceTypesSlice.reducer
