import { createSlice } from '@reduxjs/toolkit'

const initialModel = {
  code: '',
  name: '',
  brandId: 0,
}

const initialBrands = {
  items: [],
}

export const ModelSlice = createSlice({
  name: 'Model',
  initialState: {
    models: { ...initialBrands },
    addingModel: { ...initialModel },
    updatingModel: { ...initialModel },
    deleteModelId: null,
    visibleAdd: false,
    visibleUpdate: false,
  },
  reducers: {
    GetModels: (state, action) => {
      state.models = action.payload
    },
    GetModelsByBrandId: (state, action) => {
      state.models.items = action.payload
    },
    OpenAddModel: (state, action) => {
      state.visibleAdd = true
      state.addingModel = { ...initialModel }
    },
    CloseAddModel: (state, action) => {
      state.visibleAdd = false
    },
    OpenUpdateModel: (state, action) => {
      state.visibleUpdate = true
      state.updatingModel = action.payload
    },
    CloseUpdateModel: (state, action) => {
      state.visibleUpdate = false
      state.updatingModel = { ...initialModel }
    },
    AddModel: (state, action) => {
      state.addingModel = { ...initialModel }
    },
    UpdateModel: (state, action) => {
      state.updatingModel = action.payload
    },
    DeleteModel: (state, action) => {
      state.deleteModelId = action.payload
    },
  },
})

export const {
  GetModels,
  GetModelsByBrandId,
  OpenAddModel,
  AddModel,
  CloseAddModel,
  UpdateModel,
  DeleteModel,
  OpenUpdateModel,
  CloseUpdateModel,
} = ModelSlice.actions

export default ModelSlice.reducer
