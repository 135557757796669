import { createSlice } from '@reduxjs/toolkit'

export const OrderPartSlice = createSlice({
  name: 'OrderPart',
  initialState: {
    orderParts: [],
    visibleAdd: false,
  },
  reducers: {
    SetOrderParts: (state, action) => {
      state.orderParts = action.payload
    },
    SetVisibleAdd: (state, action) => {
      state.visibleAdd = action.payload
    },
    AddOrderPart: (state, action) => {
      state.orderParts = [...state.orderParts, action.payload]
    },
    RemoveOrderPart: (state, action) => {
      state.orderParts = state.orderParts.filter((item) => item.id !== action.payload.id)
    },
  },
})

export const { SetOrderParts, SetVisibleAdd, AddOrderPart, RemoveOrderPart } =
  OrderPartSlice.actions

export default OrderPartSlice.reducer
