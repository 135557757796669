import { createSlice } from '@reduxjs/toolkit'

const initialBrand = {
  code: '',
  name: '',
  equipmentId: '0',
}

const initialBrands = {
  items: [],
}

export const BrandSlice = createSlice({
  name: 'Brand',
  initialState: {
    brands: { ...initialBrands },
    addingBrand: { ...initialBrand },
    updatingBrand: { ...initialBrand },
    deleteBrandId: null,
    visibleAdd: false,
    visibleUpdate: false,
  },
  reducers: {
    GetBrands: (state, action) => {
      state.brands = action.payload
    },
    GetBrandsByEquipmentId: (state, action) => {
      state.brands.items = action.payload
    },
    OpenAddBrand: (state, action) => {
      state.visibleAdd = true
      state.addingBrand = { ...initialBrand }
    },
    CloseAddBrand: (state, action) => {
      state.visibleAdd = false
    },
    OpenUpdateBrand: (state, action) => {
      state.visibleUpdate = true
      state.updatingBrand = action.payload
    },
    CloseUpdateBrand: (state, action) => {
      state.visibleUpdate = false
      state.updatingBrand = { ...initialBrand }
    },
    AddBrand: (state, action) => {
      state.addingBrand = { ...initialBrand }
    },
    UpdateBrand: (state, action) => {
      state.updatingBrand = action.payload
    },
    DeleteBrand: (state, action) => {
      state.deleteBrandId = action.payload
    },
  },
})

export const {
  GetBrands,
  OpenAddBrand,
  AddBrand,
  CloseAddBrand,
  UpdateBrand,
  DeleteBrand,
  OpenUpdateBrand,
  CloseUpdateBrand,
  GetBrandsByEquipmentId,
} = BrandSlice.actions

export default BrandSlice.reducer
