import { createSlice } from '@reduxjs/toolkit'

const initialEquipment = {
  name: '',
}

export const EquipmentSlice = createSlice({
  name: 'Equipment',
  initialState: {
    equipments: {},
    addingEquipment: { ...initialEquipment },
    updatingEquipment: { ...initialEquipment },
    deleteEquipmentId: null,
    visibleAdd: false,
    visibleUpdate: false,
  },
  reducers: {
    GetEquipments: (state, action) => {
      state.equipments = action.payload
    },
    OpenAddEquipment: (state, action) => {
      state.visibleAdd = true
      state.addingEquipment = { ...initialEquipment }
    },
    CloseAddEquipment: (state, action) => {
      state.visibleAdd = false
    },
    OpenUpdateEquipment: (state, action) => {
      state.visibleUpdate = true
      state.updatingEquipment = action.payload
    },
    CloseUpdateEquipment: (state, action) => {
      state.visibleUpdate = false
      state.updatingEquipment = { ...initialEquipment }
    },
    AddEquipment: (state, action) => {
      state.addingEquipment = { ...initialEquipment }
    },
    UpdateEquipment: (state, action) => {
      state.updatingEquipment = action.payload
    },
    DeleteEquipment: (state, action) => {
      state.deleteEquipmentId = action.payload
    },
  },
})

export const {
  GetEquipments,
  OpenAddEquipment,
  AddEquipment,
  CloseAddEquipment,
  UpdateEquipment,
  DeleteEquipment,
  OpenUpdateEquipment,
  CloseUpdateEquipment,
} = EquipmentSlice.actions

export default EquipmentSlice.reducer
