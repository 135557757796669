import { createSlice } from '@reduxjs/toolkit'

const initialPart = {
  name: '',
  description: '',
}

const initialParts = {
  items: [],
}

export const PartSlice = createSlice({
  name: 'Part',
  initialState: {
    parts: { ...initialParts },
    addingPart: { ...initialPart },
    updatingPart: { ...initialPart },
    deletePartId: null,
    visibleAdd: false,
    visibleUpdate: false,
  },
  reducers: {
    GetParts: (state, action) => {
      state.parts = action.payload
    },
    GetPartsNoPaginated: (state, action) => {
      state.parts.items = action.payload
    },
    OpenAddPart: (state, action) => {
      state.visibleAdd = true
      state.addingPart = { ...initialPart }
    },
    CloseAddPart: (state, action) => {
      state.visibleAdd = false
    },
    OpenUpdatePart: (state, action) => {
      state.visibleUpdate = true
      state.updatingPart = action.payload
    },
    CloseUpdatePart: (state, action) => {
      state.visibleUpdate = false
      state.updatingPart = { ...initialPart }
    },
    AddPart: (state, action) => {
      state.addingPart = { ...initialPart }
    },
    UpdatePart: (state, action) => {
      state.updatingPart = action.payload
    },
    DeletePart: (state, action) => {
      state.deletePartId = action.payload
    },
  },
})

export const {
  GetParts,
  OpenAddPart,
  AddPart,
  CloseAddPart,
  UpdatePart,
  DeletePart,
  OpenUpdatePart,
  CloseUpdatePart,
  GetPartsNoPaginated,
} = PartSlice.actions

export default PartSlice.reducer
